// Определение маршрутов приложения с использованием Enum
export enum RouteKeys {
	LOGIN = 'LOGIN',
	RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST',
	RESET_PASSWORD = 'RESET_PASSWORD',
	WELCOME = 'WELCOME',
	USERS = 'USERS',
	USER_PERMISSIONS = 'USER_PERMISSIONS',
	ACCOUNTS = 'ACCOUNTS',
	NOT_FOUND = 'NOT_FOUND',
}

export const ROUTES: Record<RouteKeys, string> = {
	[RouteKeys.LOGIN]: '/login',
	[RouteKeys.RESET_PASSWORD_REQUEST]: '/password-reset-request',
	[RouteKeys.RESET_PASSWORD]: '/password-reset',
	[RouteKeys.WELCOME]: '/welcome',
	[RouteKeys.USERS]: '/users',
	[RouteKeys.USER_PERMISSIONS]: '/user-permissions',
	[RouteKeys.ACCOUNTS]: '/accounts',
	[RouteKeys.NOT_FOUND]: '*',
} as const;

// Заголовки меню приложения

export const MENU_TITLES: Record<RouteKeys, string> = {
	[RouteKeys.LOGIN]: '',
	[RouteKeys.RESET_PASSWORD_REQUEST]: '',
	[RouteKeys.RESET_PASSWORD]: '',
	[RouteKeys.WELCOME]: 'Главная',
	[RouteKeys.USERS]: 'Список пользователей',
	[RouteKeys.USER_PERMISSIONS]: 'Права пользователей',
	[RouteKeys.ACCOUNTS]: 'Аккаунты',
	[RouteKeys.NOT_FOUND]: '',
} as const;
