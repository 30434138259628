import { baseQueryWithReauth } from '@/shared/utils/apiBase';
import { createApi } from '@reduxjs/toolkit/query/react';
import { Account, Filters } from '@/modules/accounts/types';

// API для работы с аккаунтами
export const accountsApi = createApi({
	reducerPath: 'accountsApi',
	baseQuery: baseQueryWithReauth,
	tagTypes: ['Accounts'],
	endpoints: (builder) => ({
		// Получение списка аккаунтов с фильтрацией
		getAccounts: builder.query<Account[], Partial<Filters>>({
			query: (filters) => {
				const params = new URLSearchParams();
				if (filters.id) params.append('id', filters.id.toString());
				if (filters.name) params.append('name', filters.name);
				if (filters.status) params.append('status', filters.status);
				if (filters.startDate) params.append('startDate', filters.startDate);
				if (filters.endDate) params.append('endDate', filters.endDate);

				return {
					url: params.toString() ? `/accounts?${params.toString()}` : '/accounts',
				};
			},
			providesTags: (result) =>
				result
					? [
							...result.map(({ id }) => ({ type: 'Accounts' as const, id })),
							{ type: 'Accounts', id: 'LIST' },
						]
					: [{ type: 'Accounts', id: 'LIST' }],
		}),

		// Создание аккаунта
		createAccount: builder.mutation<Account, Partial<Account>>({
			query: (body) => ({
				url: '/accounts',
				method: 'POST',
				body,
			}),
			invalidatesTags: ['Accounts'],
		}),

		// Обновление аккаунта
		updateAccount: builder.mutation<Account, Partial<Account> & { id: number }>({
			query: ({ id, ...body }) => {
				return {
					url: `/accounts/${id}`,
					method: 'PUT',
					body,
				};
			},
			invalidatesTags: (_result, _error, { id }) => [
				{ type: 'Accounts', id },
				{ type: 'Accounts', id: 'LIST' },
			],
		}),

		// Изменение пароля аккаунта
		changePassword: builder.mutation<void, { id: number; password: string }>({
			query: ({ id, password }) => ({
				url: `/accounts/${id}/change-password`,
				method: 'POST',
				body: { password },
			}),
			invalidatesTags: (_result, _error, { id }) => [
				{ type: 'Accounts', id },
				{ type: 'Accounts', id: 'LIST' },
			],
		}),

		// Мягкое удаление аккаунта
		softDeleteAccount: builder.mutation<void, number>({
			query: (id) => ({
				url: `/accounts/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: (_result, _error, id) => [
				{ type: 'Accounts', id },
				{ type: 'Accounts', id: 'LIST' },
			],
		}),
	}),
});

export const {
	useGetAccountsQuery,
	useCreateAccountMutation,
	useUpdateAccountMutation,
	useChangePasswordMutation,
	useSoftDeleteAccountMutation,
} = accountsApi;
