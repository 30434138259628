import { lazy } from 'react';
import { ROUTES } from '@/shared/constants/routes';
import { Navigate, RouteObject } from 'react-router-dom';

// Ленивая загрузка компонентов
/* eslint-disable react-refresh/only-export-components */
const MainLayout = lazy(() => import('@/shared/layouts/MainLayout'));
const Login = lazy(() => import('@/modules/auth/components/Login/Login'));
const Welcome = lazy(() => import('@/modules/welcome/components/Welcome'));
const PasswordResetRequest = lazy(
	() => import('@/modules/auth/components/PasswordResetRequest/PasswordResetRequest'),
);
const PasswordReset = lazy(() => import('@/modules/auth/components/PasswordReset/PasswordReset'));
const NotFound = lazy(() => import('@/shared/components/NotFound/NotFound'));
const Users = lazy(() => import('@/modules/users/components/Users/Users'));
const UserPermissions = lazy(
	() => import('@/modules/users/components/UserPermissions/UserPermissions'),
);
const Accounts = lazy(() => import('@/modules/accounts/components/Accounts/Accounts'));

// Публичные маршруты
export const publicRoutes: RouteObject[] = [
	// Логин
	{ path: ROUTES.LOGIN, element: <Login /> },
	// Сброс пароля
	{ path: ROUTES.RESET_PASSWORD_REQUEST, element: <PasswordResetRequest /> },
	{ path: ROUTES.RESET_PASSWORD, element: <PasswordReset /> },
	// Страница 404
	{ path: ROUTES.NOT_FOUND, element: <NotFound /> },
];

// Приватные маршруты
export const privateRoutes: RouteObject[] = [
	{
		path: '/',
		element: <MainLayout />,
		children: [
			// Редирект на приветствие
			{ index: true, element: <Navigate to={ROUTES.WELCOME} replace /> },
			// Приветствие
			{ path: ROUTES.WELCOME, element: <Welcome /> },
			// Пользователи
			{ path: ROUTES.USERS, element: <Users /> },
			// Пользовательские права
			{ path: ROUTES.USER_PERMISSIONS, element: <UserPermissions /> },
			// Аккаунты
			{ path: ROUTES.ACCOUNTS, element: <Accounts /> },
		],
	},
];
