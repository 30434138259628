import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthState, User } from '@/modules/auth/types';
import {
	getLocalStorageToken,
	removeLocalStorageToken,
	setLocalStorageToken,
} from '@/shared/utils/localStorage';

// Начальное состояние авторизации
const initialState: AuthState = {
	user: null,
	token: getLocalStorageToken(),
};

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		// Устанавливает пользователя
		setUser(state, action: PayloadAction<User | null>) {
			state.user = action.payload;
		},
		// Устанавливает токен и обновляет localStorage
		setToken(state, action: PayloadAction<string | null>) {
			state.token = action.payload;
			action.payload ? setLocalStorageToken(action.payload) : removeLocalStorageToken();
		},
		// Выполняет логаут пользователя
		logout(state) {
			state.user = null;
			state.token = null;
			removeLocalStorageToken();
		},
	},
});

export const { setUser, setToken, logout } = authSlice.actions;

export default authSlice.reducer;
