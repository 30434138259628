interface EnvConfig {
	API_BASE_URL: string;
	IS_PRODUCTION: boolean;
}

// Конфиг окружения, беру переменные из Vite
export const ENV: EnvConfig = {
	API_BASE_URL: import.meta.env.VITE_API_BASE_URL || '',
	IS_PRODUCTION: import.meta.env.PROD || false,
};
