import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { QueryReturnValue } from '@reduxjs/toolkit/query/react';
import { AppDispatch } from '@/app/store/store';
import { QUERY_MESSAGES } from '@/shared/constants/loadingMessages';

interface LoadingState {
	loading: boolean;
	loadingMessage: string;
}

const initialState: LoadingState = {
	loading: false,
	loadingMessage: '',
};

const loadingSlice = createSlice({
	name: 'loading',
	initialState,
	reducers: {
		// Устанавливаю флаг и сообщение загрузки
		startLoading(state, action: PayloadAction<Partial<LoadingState>>) {
			state.loading = action.payload.loading ?? state.loading; // Если не указан флаг, оставляю текущее значение
			state.loadingMessage = action.payload.loadingMessage || state.loadingMessage; // Обновляю сообщение
		},
		// Останавливаю загрузку, сбрасываю сообщение
		stopLoading(state) {
			state.loading = false;
			state.loadingMessage = '';
		},
	},
});

interface OnQueryStartedParams {
	loadingMessage: string;
}

const handleLoading =
	({ loadingMessage }: OnQueryStartedParams) =>
	async (queryFulfilled: Promise<QueryReturnValue>, dispatch: AppDispatch) => {
		dispatch(loadingSlice.actions.startLoading({ loading: true, loadingMessage }));
		try {
			await queryFulfilled;
		} catch (error) {
			// Игнорирую ошибки, можно добавить обработку при необходимости
		} finally {
			dispatch(loadingSlice.actions.stopLoading());
		}
	};

// Обработка загрузки для запроса
export async function handleQueryLoading(
	key: keyof typeof QUERY_MESSAGES,
	queryFulfilled: Promise<QueryReturnValue>,
	dispatch: AppDispatch,
) {
	const { loadingMessage } = QUERY_MESSAGES[key];
	await handleLoading({ loadingMessage })(queryFulfilled, dispatch);
}

export const { startLoading, stopLoading } = loadingSlice.actions;
export default loadingSlice.reducer;
