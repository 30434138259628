import { createApi } from '@reduxjs/toolkit/query/react';
import { AuthTokenResponse, LoginParams, User } from '@/modules/auth/types';
import { API_ENDPOINTS } from '@/shared/constants/apiEndpoints';
import { baseQueryWithReauth } from '@/shared/utils/apiBase';
import { handleQueryLoading } from '@/app/store/loadingSlice';

// Создаю API с RTK Query для авторизации
export const authApi = createApi({
	reducerPath: 'authApi',
	baseQuery: baseQueryWithReauth, // Общий baseQuery с обработкой токена
	endpoints: (builder) => ({
		// Логин
		login: builder.mutation<AuthTokenResponse, LoginParams>({
			query: (credentials: LoginParams) => ({
				url: API_ENDPOINTS.AUTH.LOGIN,
				method: 'POST',
				body: credentials,
			}),
			// Отслеживаю статус запроса логина
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await handleQueryLoading('login', queryFulfilled, dispatch);
			},
		}),
		// Логаут
		logout: builder.mutation<void, void>({
			query: () => ({
				url: API_ENDPOINTS.AUTH.LOGOUT,
				method: 'POST',
			}),
		}),
		// Данные пользователя
		getUser: builder.query<User, void>({
			query: () => ({
				url: API_ENDPOINTS.USER.INFO,
				method: 'GET',
			}),
			// Отслеживаю статус запроса данных пользователя
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await handleQueryLoading('getUser', queryFulfilled, dispatch);
			},
		}),
		// Обновление токена
		refreshToken: builder.mutation<AuthTokenResponse, void>({
			query: () => ({
				url: API_ENDPOINTS.AUTH.REFRESH_TOKEN,
				method: 'GET',
			}),
			// Отслеживаю статус запроса обновления токена
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await handleQueryLoading('refreshToken', queryFulfilled, dispatch);
			},
		}),
		// Запрос на сброс пароля
		passwordResetRequest: builder.mutation<{ token: string }, { email: string }>({
			query: ({ email }) => ({
				url: API_ENDPOINTS.AUTH.RESET_PASSWORD_REQUEST,
				method: 'POST',
				body: { email },
			}),
			// Отслеживаю статус запроса на сброс пароля
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await handleQueryLoading('passwordResetRequest', queryFulfilled, dispatch);
			},
		}),
		// Сброс пароля
		passwordReset: builder.mutation<void, { token: string; newPassword: string }>({
			query: ({ token, newPassword }) => ({
				url: API_ENDPOINTS.AUTH.RESET_PASSWORD,
				method: 'POST',
				body: { token, newPassword },
			}),
			// Отслеживаю статус запроса сброса пароля
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await handleQueryLoading('passwordReset', queryFulfilled, dispatch);
			},
		}),
	}),
});

// Экспорт хуков для работы с API
export const {
	useLoginMutation,
	useLogoutMutation,
	useGetUserQuery,
	useRefreshTokenMutation,
	usePasswordResetRequestMutation,
	usePasswordResetMutation,
} = authApi;
