import { BaseQueryFn, FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ENV } from '@/config/env';
import { getLocalStorageToken, setLocalStorageToken } from '@/shared/utils/localStorage';
import { logout, setToken } from '@/modules/auth/store/authSlice';
import { API_ENDPOINTS } from '@/shared/constants/apiEndpoints';
import { authApi } from '@/modules/auth/api/authApi';
import { AppDispatch } from '@/app/store/store';
import { startLoading } from '@/app/store/loadingSlice';
import { QUERY_MESSAGES } from '@/shared/constants/loadingMessages';
import { HTTP_STATUS_CODES } from '@/shared/constants/httpStatusCodes';

const baseQuery = fetchBaseQuery({
	baseUrl: ENV.API_BASE_URL,
	prepareHeaders: (headers) => {
		const token = getLocalStorageToken();
		if (token) {
			headers.set('Authorization', `Bearer ${token}`);
		}
		headers.set('Origin', window.location.origin);
		return headers;
	},
	credentials: 'include',
});

// Вспомогательная функция для проверки, является ли запрос запросом на обновление токена
function isRefreshTokenRequest(args: string | FetchArgs): boolean {
	return (
		typeof args === 'object' && 'url' in args && args.url === API_ENDPOINTS.AUTH.REFRESH_TOKEN
	);
}

export const baseQueryWithReauth: BaseQueryFn<string | FetchArgs> = async (
	args,
	api,
	extraOptions,
) => {
	let result = await baseQuery(args, api, extraOptions);

	const dispatch = api.dispatch as AppDispatch;

	// Проверяем, это запрос на логаут?
	const checkLogout =
		typeof args === 'object' && 'url' in args && args.url === API_ENDPOINTS.AUTH.LOGOUT;

	if (result.error) {
		if (result.error.status === HTTP_STATUS_CODES.UNAUTHORIZED) {
			if (isRefreshTokenRequest(args) || checkLogout) {
				// Если это запрос на обновление токена и он вернул 401, разлогиниваем пользователя
				dispatch(logout());
				return result;
			}

			// Пытаемся обновить токен через мутацию
			const refreshResult = await dispatch(authApi.endpoints.refreshToken.initiate());

			if (refreshResult.data) {
				// Обновляем токен в localStorage и Redux
				const { token: newToken } = refreshResult.data as { token: string };
				setLocalStorageToken(newToken);
				dispatch(setToken(newToken));

				// Проверяем, есть ли endpoint в `QUERY_MESSAGES`
				const endpoint = api.endpoint as keyof typeof QUERY_MESSAGES;
				if (QUERY_MESSAGES[endpoint]) {
					const { loadingMessage } = QUERY_MESSAGES[endpoint];
					dispatch(startLoading({ loading: true, loadingMessage }));
				}

				// Повторяем исходный запрос с новым токеном
				result = await baseQuery(args, api, extraOptions);
			} else {
				// Если обновление токена не удалось, разлогиниваем пользователя
				dispatch(logout());
			}
		}
	}

	return result;
};
