import { useEffect } from 'react';
import { useGetUserQuery } from '@/modules/auth/api/authApi';
import { useAppDispatch, useAppSelector } from '@/app/store/hooks';
import { setUser } from '../store/authSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import { isPrivateRoute } from '@/shared/utils/routesUtils';
import { ROUTES } from '@/shared/constants/routes';

interface UseAuthInitializationReturn {
	loading: boolean;
	loadingMessage: string;
}

// Хук для инициализации аутентификации
const useAuthInitialization = (): UseAuthInitializationReturn => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const location = useLocation();

	// Получаю токен и состояние загрузки из хранилища
	const token = useAppSelector((state) => state.auth.token);
	const loading = useAppSelector((state) => state.loading.loading);
	const loadingMessage = useAppSelector((state) => state.loading.loadingMessage);

	// Проверяю, приватный ли текущий маршрут
	const isOnPrivateRoute = isPrivateRoute(location.pathname);

	// Запрашиваю данные пользователя, если есть токен
	const { data: userData } = useGetUserQuery(undefined, {
		skip: !token,
	});

	// Функция для перенаправления на страницу логина
	const redirectToLogin = () => {
		if (isOnPrivateRoute) {
			navigate(ROUTES.LOGIN, { replace: true });
		}
	};

	useEffect(() => {
		// Если нет токена, перенаправляю на логин
		if (!token) {
			redirectToLogin();
			return;
		}
		// Если получены данные пользователя, сохраняю их в хранилище
		if (userData) {
			dispatch(setUser(userData));
		}
	}, [token, userData, dispatch]);

	return { loading, loadingMessage };
};

export default useAuthInitialization;
