import { privateRoutes } from '@/shared/config/routesConfig';
import { RouteObject } from 'react-router-dom';

// Универсальная функция для поиска маршрута в конфигурации
const checkRoute = (routes: RouteObject[], path: string): boolean => {
	return routes.some((route) => {
		if (route.path === path) return true;
		if (route.children) return checkRoute(route.children, path);
		return false;
	});
};

// Проверка на приватный маршрут
export const isPrivateRoute = (pathname: string) => {
	return checkRoute(privateRoutes, pathname);
};
