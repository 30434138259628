// Утилиты для работы с localStorage
export const getLocalStorageToken = () => localStorage.getItem('token');

export const setLocalStorageToken = (token: string) => {
	localStorage.setItem('token', token);
};

export const removeLocalStorageToken = () => {
	localStorage.removeItem('token');
};
