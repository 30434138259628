// Сообщения о загрузке
export const LOADING_MESSAGES = {
	AUTHENTICATING: 'Выполняем авторизацию',
	REFRESHING_TOKEN: 'Обновление токена',
	LOADING_USER_DATA: 'Загрузка данных пользователя',
	LOGGING_OUT: 'Выполняем выход',
	RESET_PASSWORD_EMAIL: 'Отправляем письмо для сброса пароля',
	RESETTING_PASSWORD: 'Сбрасываем пароль',
	LOADING_APP: 'Загрузка модуля',
} as const;

// Тип префиксов сообщений
type LoadingMessagePrefixes = keyof typeof LOADING_MESSAGES;

// Маппинг сообщений для QUERY_MESSAGES
const createQueryMessages = (prefix: LoadingMessagePrefixes) => ({
	loadingMessage: LOADING_MESSAGES[prefix],
});

// QUERY_MESSAGES
export const QUERY_MESSAGES: Record<string, { loadingMessage: string }> = {
	login: createQueryMessages('AUTHENTICATING'),
	logout: createQueryMessages('LOGGING_OUT'),
	getUser: createQueryMessages('LOADING_USER_DATA'),
	refreshToken: createQueryMessages('REFRESHING_TOKEN'),
	passwordResetRequest: createQueryMessages('RESET_PASSWORD_EMAIL'),
	passwordReset: createQueryMessages('RESETTING_PASSWORD'),
	loadingApp: createQueryMessages('LOADING_APP'),
};
