import { combineReducers, configureStore, Middleware } from '@reduxjs/toolkit';
import authReducer from '@/modules/auth/store/authSlice';
import { authApi } from '@/modules/auth/api/authApi';
import { setupListeners } from '@reduxjs/toolkit/query';
import loadingReducer from './loadingSlice';
import { ENV } from '@/config/env';
import { usersApi } from '@/modules/users/api/usersApi';
import { accountsApi } from '@/modules/accounts/api/accountsApi';

// Собираю все редьюсеры
const rootReducer = combineReducers({
	auth: authReducer,
	loading: loadingReducer,
	// Редьюсеры RTK Query для API
	[authApi.reducerPath]: authApi.reducer,
	[usersApi.reducerPath]: usersApi.reducer,
	[accountsApi.reducerPath]: accountsApi.reducer,
});

const middleware: Middleware[] = [authApi.middleware, usersApi.middleware, accountsApi.middleware];

// Настраиваю store
const store = configureStore({
	reducer: rootReducer,
	// Подключаю middleware для работы с API
	middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(...middleware),
	// Включаю DevTools только для разработки
	devTools: !ENV.IS_PRODUCTION,
});

// Автоматический рефетч данных при старте приложения
setupListeners(store.dispatch);

// Типы для состояния и dispatch
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
