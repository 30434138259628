import { baseQueryWithReauth } from '@/shared/utils/apiBase';
import { createApi } from '@reduxjs/toolkit/query/react';
import { Filters, User } from '@/modules/users/types';

export const usersApi = createApi({
	reducerPath: 'usersApi',
	baseQuery: baseQueryWithReauth,
	tagTypes: ['Users'],
	endpoints: (builder) => ({
		// Получение пользователей с фильтрацией
		getUsers: builder.query<User[], Partial<Filters>>({
			query: (filters) => {
				// Формирование параметров запроса
				const params = new URLSearchParams();

				if (filters.id) params.append('id', filters.id.toString());
				if (filters.email) params.append('email', filters.email);
				if (filters.startDate) params.append('startDate', filters.startDate);
				if (filters.endDate) params.append('endDate', filters.endDate);
				if (filters.role) params.append('role', filters.role);
				if (filters.status) params.append('status', filters.status);

				return {
					// URL с параметрами
					url: `/users${params.toString() ? `?${params.toString()}` : ''}`,
				};
			},
			providesTags: (result) =>
				result
					? [
							// Кэширование пользователей по ID
							...result.map(({ id }) => ({ type: 'Users' as const, id })),
							{ type: 'Users', id: 'LIST' },
						]
					: [{ type: 'Users', id: 'LIST' }],
		}),
		// Создание пользователя
		createUser: builder.mutation<User, Partial<User>>({
			query: (body) => ({
				url: '/users',
				method: 'POST',
				body,
			}),
			invalidatesTags: ['Users'], // Обновление кэша
		}),
		// Обновление пользователя
		updateUser: builder.mutation<User, Partial<User> & { id: number }>({
			query: ({ id, ...body }) => {
				return {
					url: `/users/${id}`,
					method: 'PUT',
					body,
				};
			},
			invalidatesTags: (_result, _error, { id }) => [
				{ type: 'Users', id },
				{ type: 'Users', id: 'LIST' },
			],
		}),
		// Изменение пароля
		changePassword: builder.mutation<void, { id: number; password: string }>({
			query: ({ id, password }) => ({
				url: `/users/${id}/change-password`,
				method: 'POST',
				body: { password },
			}),
			invalidatesTags: (_result, _error, { id }) => [
				{ type: 'Users', id },
				{ type: 'Users', id: 'LIST' },
			],
		}),
		// Мягкое удаление пользователя
		softDeleteUser: builder.mutation<void, number>({
			query: (id) => ({
				url: `/users/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: (_result, _error, id) => [
				{ type: 'Users', id },
				{ type: 'Users', id: 'LIST' },
			],
		}),
	}),
});

export const {
	useGetUsersQuery,
	useCreateUserMutation,
	useUpdateUserMutation,
	useChangePasswordMutation,
	useSoftDeleteUserMutation,
} = usersApi;
