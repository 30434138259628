// API конечные точки для аутентификации и других операций
export const API_ENDPOINTS = {
	AUTH: {
		LOGIN: 'admin/v1/auth/login',
		LOGOUT: 'admin/v1/auth/logout',
		REFRESH_TOKEN: 'users/v1/auth/refresh-token',
		RESET_PASSWORD_REQUEST: 'users/v1/account/password-reset-request',
		RESET_PASSWORD: 'users/v1/account/reset-password',
	},
	USER: {
		INFO: 'admin/v1/user/info',
	},
	DIRECTORY: {
		ROLE: (id?: number) => (id ? `admin/v1/directory/role/${id}` : 'admin/v1/directory/role'),
		STATUS: (id?: number) =>
			id ? `admin/v1/directory/status/${id}` : 'admin/v1/directory/status',
	},
};
