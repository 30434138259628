import React from 'react';
import styles from './LoadingSpinner.module.scss';

// Типы пропсов для компонента
interface LoadingSpinnerProps {
	message?: string;
}

// Компонент спиннера загрузки
const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ message = '' }) => (
	<div className={styles.spinnerContainer}>
		<div className={styles.cubeContainer}>
			<div className={styles.cube}>
				<div className={`${styles.face} ${styles.front}`}></div>
				<div className={`${styles.face} ${styles.back}`}></div>
				<div className={`${styles.face} ${styles.left}`}></div>
				<div className={`${styles.face} ${styles.right}`}></div>
				<div className={`${styles.face} ${styles.top}`}></div>
				<div className={`${styles.face} ${styles.bottom}`}></div>
			</div>
		</div>
		{message && <p className={`${styles.message} dots-animation`}>{message}</p>}
	</div>
);

export default LoadingSpinner;
